<script lang="ts" setup>
const isShowBanner = useCookie('isShowBanner', {
  default: () => true,
})
const { t } = useI18n()
const toggleShowBanner = useToggle(isShowBanner)
const localeRoute = useLocaleRoute()
</script>

<template>
  <Transition>
    <div class="banner" v-show="isShowBanner">
      <div class="banner-content">
        <div class="banner-left">
          <div class="banner-left-item gap-5">
            <div
              class="text-white text-xs min-[414px]:text-sm sm:text-base tracking-wide"
            >
              Try our Gasless NFT checkout (powered by Belong SDK)
            </div>
            <NuxtLink
              to="https://app.belong.net/payments?target=event-ticket&eventId=6706cb091a118ca18749b42d"
              target="_blank"
              class="flex flex-row items-center text-center justify-center bg-black text-sm sm:text-base rounded-full pl-3.5 pr-4.5 py-1.5 whitespace-pre"
            >
              <p class="text-white">Mint free pass</p>
            </NuxtLink>
          </div>
        </div>
        <button class="banner-button-close" @click="toggleShowBanner(false)">
          <img
            class="w-9.75 h-9.75 object-cover"
            :alt="t('banner-close')"
            src="~/assets/images/home/banner-close.svg"
          />
        </button>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.banner {
  @apply bg-[#7540EE] w-full h-21.25 flex flex-row items-center overflow-hidden z-3 relative;
}
.banner-content {
  @apply flex flex-row justify-center w-full pl-2.5;
}
.banner-left {
  @apply flex justify-between max-w-274 w-full;
}
.banner-left-item {
  @apply flex flex-row items-center;
}
.banner-title {
  @apply text-white text-lg tracking-wide;
}

.banner-title-limited-time {
  @apply hidden sm:flex;
}

.banner-title-limited-time-mobile {
  @apply text-sm sm:hidden;
}

.banner-button-founder {
  @apply hidden sm:flex;
}

.banner-button-founder-mobile {
  @apply text-[13px] sm:hidden;
}
.banner-button-close {
  @apply cursor-pointer ml-3;
}

.banner-image {
  @apply w-94.75 h-21.25 hidden lg:flex;
}

@media (min-width: 424.9px) {
  .banner-content {
    @apply px-4;
  }
  .banner-button {
    @apply w-auto ml-9;
  }

  .banner-button-founder-mobile {
    @apply text-sm;
  }
}
</style>
